.hotdesk_calendar table.fc-scrollgrid-sync-table tbody tr {
  grid-template-columns: repeat(7, 14.285714286%);
  display: grid;
}

.hotdesk_calendar .fc .fc-daygrid-month-start {
  font-weight: 400;
}

.hotdesk_calendar table.fc-scrollgrid thead tr th {
  display: none;
}

.hotdesk_calendar table.fc-scrollgrid thead tr th:nth-child(-n + 7) {
  display: table-cell;
}

/* Calender Settings */

.fc-day-today {
  background-color: white;
}

.MuiAvatar-root.invoice img {
  height: auto !important;
}

.fc .fc-timegrid-col.fc-day-today {
  background-color: rgba(115, 103, 240, 0.05);
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: aliceblue;
}
.fc-theme-standard th {
  background-color: white;
}
.fc-timegrid-event {
  background-color: rgba(115, 103, 240, 0.16);
  border: none;
}
.fc .fc-view-harness {
  z-index: 0;
}

.fc .fc-next-button {
  background-color: transparent !important;
}
.fc .fc-prev-button {
  background-color: transparent !important;
}

.fc .fc-timegrid-axis-frame {
  justify-content: flex-start;
  white-space: nowrap !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  padding: 1.5rem;
  margin-bottom: 0px !important;
}
.fc-list-event,
.fc-list-empty {
  cursor: pointer;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background-color: rgba(115, 103, 240, 0.4) !important;
}

.fc-button-primary {
  border: none !important;

  color: rgb(115, 103, 240) !important;
  background-color: rgba(115, 103, 240, 0.16) !important;
  outline: none;
}

.fc-button:focus {
  box-shadow: none !important;
}
.fc-header-toolbar {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

@media (max-width: 1027px) {
  .fc-header-toolbar {
    flex-wrap: wrap;
  }

  .fc-header-toolbar .fc-center {
    order: 1;
    flex-grow: 0;
  }

  .fc-header-toolbar > div {
    padding: 0px 5px 5px 5px;
  }

  .fc-toolbar-chunk .fc-button-group {
    padding: 5px;
  }

  .fc-header-toolbar .fc-right {
    order: 2;
  }
}

.fc-toolbar-chunk .fc-bookingButton-button {
  border-color: #7367f0 !important;
  background-color: #7367f0 !important;
  color: white !important;
}
.fc-toolbar-chunk .fc-bookingButton-button:hover {
  box-shadow: 0 8px 25px -8px #7367f0 !important;
}
.fc-day-today {
  background-color: white;
}
.resource_calendar table.fc-scrollgrid-sync-table tbody tr {
  display: contents !important;
}

/* .resource_calendar{
  max-height: 78vh !important;
overflow: auto;
} */

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 25px;
}


.fc .fc-col-header-cell-cushion{
  width: 100%;
}